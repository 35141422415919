import api from "@/services/api";
import axios from "axios";

export default {
    getBlobs: function (file) {
        const blobSize = 1024 * 1024 * 50; // 50MB chunks
        const blobCount = Math.ceil(file.size/blobSize);
        var blobs = [];

        for(var i = 0; i < blobCount; i++) {
            var start = i * blobSize;
            var end = start + blobSize;
            var blob = file.slice(start, end);

            blobs.push(blob)
        }

        return blobs;
    },
    getExtension: function (file) {
        return file.name.toLowerCase().split('.').pop();
    },
    getMimetype: function (fileExtension) {
        switch(fileExtension)
        {
            case 'stl': return 'model/stl';
            case 'ply': return 'model/ply';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            case 'heic':
                return 'image/heic';
            case 'dcm':
                return 'application/dicom';
            default: 
                console.log('Extension "' + fileExtension + '" does not have a mimetype set up');
                return null;
        }
    },
    startUpload: function (fileName, file, mimetype) {
        var blobs = this.getBlobs(file);

        return new Promise((resolve, reject) => {
            api
            .post('/api/v1/files', {
                content_type: mimetype,
                parts: blobs.length,
            })
            .then((response) => {
                var data = response.data;
                resolve({
                    fileName: fileName,
                    file: file,                     
                    parts: data.urls.map((url, index) => {
                        return {
                            part_number: index+1,
                            url: url,
                            blob: blobs[index],
                            e_tag: null, 
                            uploadedBytes: 0,
                        };
                    }),
                    key: data.file_key, 
                    uploadId: data.upload_id, 
                });
            })
            .catch((error) => {
                reject(error);
            });

        });
    },
    uploadParts: function(uploadData) {
        return new Promise((resolve, reject) => {
            var promises = [];
            uploadData.parts.forEach((part, partIndex) => {
                const config = {
                    onUploadProgress: this.updateFileData(uploadData, partIndex),
                };
                var promise = this.uploadPart(part, config)
                promises.push(promise);
            });

            Promise.all(promises)
            .then((responses) => {
                responses.forEach((response, index) => {
                    uploadData.parts[index].e_tag = response.headers.etag;
                })

                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        });
    },
    uploadPart: function(part, config) {
        return axios.put(part.url, part.blob, config);
    },
    updateFileData: function(uploadData, partIndex)
    {
        return function(progressEvent)
        {
            this.parts[partIndex].uploadedBytes = progressEvent.loaded;
            
        }.bind(uploadData)
    },
    completeUpload: function(uploadData) {
        return new Promise((resolve, reject) => {

            api
            .post('/api/v1/files/complete', {
                upload_id: uploadData.uploadId,
                file_name: uploadData.fileName, 
                file_key: uploadData.key,
                parts: uploadData.parts.map((part) => {
                    return {
                        e_tag: part.e_tag,
                        part_number: part.part_number + '', // Part number must be a string.
                    };
                }),
            })
            .then((response) => {
                resolve(response.data.file_hash);
            })
            .catch(() => {
                reject();
            });

        });
    }
};
  